import React, { useEffect, useRef } from "react";
import { useState } from "react";
import {
  Button,
  Form,
  Row,
  Col,
  Dropdown,
  Overlay,
  Table,
  InputGroup,
} from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import {
  convertMomentDate,
  convertToBaseCurrency,
  copyText,
  customerFullName,
  maxTopPopperConfig,
  nanoid,
  pcsToTons,
  qtyFormat,
  reactSelectTheme,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
  Units,
} from "./../utils/helpers";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  ExcelIcon,
  DownloadIcon,
} from "./Icons";
import NewCustomerModal from "./NewCustomerModal";
import NewItemModal from "./NewItemModal";
import PageHeader from "./PageHeader";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ItemsTable from "./utils/ItemsTable";
import "./../assets/scss/create-invoice.scss";

import currency from "currency.js";
import DotsVeritcalIcon from "mdi-react/DotsVerticalIcon";
import { cloneDeep, isEmpty, lowerCase } from "lodash";

import { useMemo } from "react";
import NumberCustomInput from "./utils/NumberCustomInput";
import { services } from "./../config";
import queryString from "query-string";
import { useQuery, useQueryClient } from "react-query";
import { queryActions, reportActions } from "./../utils/reactQueryActions";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import SelectBankDialog from "./SelectBankDialog";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useUpdateEffect,
} from "./../utils/hooks";

import ConfirmDialog from "./ConfirmDialogue";

import { useAuth } from "./../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "./utils/ModalLoader";
import printJS from "print-js";
import CustomerSelectModal from "./CustomerSelectModal";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CreatableSelect from "react-select/creatable";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import AddAccounts from "./modals/AddAccounts";
import eventBus from "../utils/EventBus";
import CogSyncOutlineIcon from "mdi-react/CogSyncOutlineIcon";
import { IsPrivileged } from "./DisplayChildElement";
import { read, utils } from "xlsx";
import ExpenseSettings from "./utils/ExpenseSettings";


export default function ExpensesEntryBulk() {
  useEffectOnce(() => scrollToTop());
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    },
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
  ];

  const cashOptions = [
    {
      label: "Cash On Hand",
      value: "Cash on hand",
    },
  ];

  const approvedbyOptions = [
    {
      label: "MD",
      value: "MD",
    },
    {
      label: "General Manager",
      value: "General Manager",
    },
    {
      label: "Admin Manager",
      value: "Admin Manager",
    },
  ];

  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const defaultCustomer = useStoreState((state) => state.defaultCustomer);
  const { backendUrl } = useAuth();
  const [showExpensesPopover, setShowExpensesPopover] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(defaultCustomer);
  // const [showJournalPopover, setShowJournalPopover] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [canAddExpense, setCanAddExpense] = useState(true);
  const [canRemoveExpense, setCanRemoveExpense] = useState(true);



  const postExpense = async (payload) => {
    if (
      payload.currency &&
      payload.currency !== generalSettings?.prevailingCurrency
    ) {
      payload = convertToBaseCurrency({
        data: payload,
        conversionAmount: payload.conversionAmount,
      });
    }

    // return;
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/transaction/expense-entry-bulk`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postExpenseMutation = useMutation((payload) => postExpense(payload), {
    onSuccess: async ({ data, message }) => {
      toast.success(message);
      setTableData([]);
      formik.resetForm();
      formik.setValues(initialValues)
      formik.setFieldValue(`expenses[0].Ref`, nanoid(11, "number"))
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const initialValues = {
    expenses: [{
      PaymentType: "",
      From: "",
      chequeNumber: "",
      payTo: "",
      Remark: "",
      Comment: "",
      Branch: "General",
      ApprovedBy: "",
      Ref: nanoid(10, "number"),
      salesDate: moment(),
      currency: generalSettings?.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
      paymentSources: [],
      Amount: "",
      Account: "",
      Particulers: "", AccountTitle: "", Type: "",
      jobAccountID: '',
      jobAccountDescription: "",
      requestid: "",
    }]
  }

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({

    }),
    onSubmit: async (values) => {
      values = cloneDeep(values);
      if (isEmpty(values.expenses)) return toast.error(`Please select`);

      let hasError = false;

      for (const [i, v] of values.expenses.entries()) {
        if (!v.Ref) {
          formik.setFieldError(
            `expenses[${i}].Ref`,
            "Required"
          );
          hasError = true;
        }

        if (!v.Amount) {
          formik.setFieldError(
            `expenses[${i}].Amount`,
            "Required"
          );
          hasError = true;
        }

        if (!v.Account) {
          formik.setFieldError(
            `expenses[${i}].Account`,
            "Required"
          );
          hasError = true;
        }

        if (!v.PaymentType) {
          formik.setFieldError(
            `expenses[${i}].PaymentType`,
            "Required"
          );
          hasError = true;
        }

        if (!v.From) {
          formik.setFieldError(
            `expenses[${i}].From`,
            "Required"
          );
          hasError = true;
        }
      }


      if (hasError) return;

      if (
        await ConfirmDialog({
          title: "Post Transaction",
          description: `Are you sure, you want to post expenses`,
        })
      ) {

        postExpenseMutation.mutate({
          expenses: values.expenses
        });
      }
    },
    onReset: () => { },
  });

  useEffectOnce(() => {
    if (location?.state) {
      // console.log(location.state);
      if (location.state?.expenses) {
        formik.setFieldValue('expenses', location.state.expenses.map(expense => {

          expense.Ref = nanoid(10, "number");

          if (expense?.job) {
            expense.jobAccountID = expense?.jobNumber;
            expense.jobAccountDescription = expense?.title;
          }

          if (expense?.Vendor_ID) {
            expense.payTo = expense?.Vendor_ID;
          }

          if (expense?.requisitionApprovedBy) {
            setCanAddExpense(false);
            setCanRemoveExpense(false);
          }

          if (expense?.paymentType) {
            expense.requisitioNpaymentType =
              expense?.paymentType
              ;

            expense.PaymentType = "None";
            expense.From = "None";
          }

          return { ...formik.initialValues.expenses[0], ...expense }
        }));
      }
      navigate(location.pathname, { replace: true });
    }
  });

  const currencySymbol = useMemo(() => {
    const foundCurrency = currenciesOptions.find(
      (el) => el.cc === formik.values.currency
    );
    return foundCurrency ? foundCurrency.symbol : "";
  }, [formik.values.currency]);

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/invoice/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.vendors = [
      {
        value: "",
        label: "One-time vendor",
      },
      ...data?.vendors.map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      })),
    ];

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    data.branch = [
      {
        value: "General",
        label: "General",
      },
    ];

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    if (!isEmpty(data?.imprest)) {
      //  formik.setFieldValue("From", data.imprest[0].value);
    }
    return data;
  };

  const {
    data = { banks: [], vendors: [], branch: [], imprest: [] },
    isLoading,
  } = useQuery([queryActions.INVOICE_SETUP], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const handleSelectedExpense = (expense) => {
    expense.Ref = "";
    setTableData([...tableData, expense]);
    setShowExpensesPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedExpense(account);
  });

  const edit = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const handleRemove = (index) => {
    formik.setFieldValue('expenses', [...formik.values.expenses.filter((el, i) => i !== index)]);
  };

  const resolveFromOptions = (PaymentType, currency) => {
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
        PaymentType
      )
        ? data.banks.filter((el) => el?.currency === currency)
        : cashOptions;
  };

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    formik.setFieldValue("From", "");
    /* if (value === "Imprest") {
      formik.setFieldValue("From", data.imprest[0].value);
    } else if (value === "Cash") {
      formik.setFieldValue("From", cashOptions[0].value);
    } else if (
      ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(value)
    ) {
      formik.setFieldValue("From", data.banks[0].value);
    } */
  };

  const total = useMemo(() => {
    return tableData
      .map((el) => el.Amount)
      .reduce((a, b) => currency(a).add(b).value, 0);
  }, [tableData]);

  const debouncedTotal = useDebounce(total, 800);
  useEffect(() => {
    if (isEmpty(formik.values.paymentSources)) {
      formik.setFieldValue('Amount', debouncedTotal)
    }
  }, [debouncedTotal, formik.values.paymentSources,]);

  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async function (e) {
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });
        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );

        if (
          !(await ConfirmDialog({
            title: "Are you sure?",
            description: `You want to import${!isEmpty(tableData)
              ? ": Existing import will be replaced"
              : ""
              }`,
          }))
        ) {
          return;
        }

        let schema = yup.object().shape({
          items: yup.array().of(
            yup.object({
              Account: yup.string().required(),
              AccountTitle: yup.string().required(),
              Type: yup.string().required(),
              Amount: yup.string().required(),
              PaymentType: yup.string().required(),
              From: yup.string().required(),
              Particulers: yup.string().required(),
              Remark: yup.string().required(),
            })
          ),
        });
        try {
          await schema.validate({ items: rows });

          // Clean Up
          rows = rows.map((expense) => {
            return {
              ...expense,
              currency: generalSettings?.prevailingCurrency,
              Ref: expense?.Ref || nanoid(10, "number"),
            };
          });

          setTableData(rows)
        } catch (err) {
          console.log(err);
          toast.error(err.name);
          toast.error(JSON.stringify(err.errors));
        }
      };
      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  return (
    <IsPrivileged roleName={["Expenses Entry"]}>
      <main className="create-invoice">
        <PageHeader
          name="Expenses Entry"
          description="Bill Payment"
          icon={<CreateInvoiceIcon />}
        />
        <div className="p-3 content">
          <div className="d-md-flex content-holder rounded">
            <section
              className="item-details"
              style={{ width: '100%', maxWidth: 'unset' }}
            >
              <div>
                <div className="actions">
                  <div>
                    <h1 className="h4">Bill Payment</h1>
                  </div>
                  <div className="d-flex gap-3">
                    <label variant="" className="btn print" title="Import" bsPrefix="">
                      <input
                        type="file"
                        className="d-none"
                        onChange={(e) =>
                          handleFile({
                            eventData: e,
                            type: "",
                          })
                        }
                        accept=".xlsx"
                        readOnly={!canAddExpense}
                      />
                      Import <ExcelIcon color="#008000" />
                    </label>

                    <a
                      href={`/excel_templates/INVEX_EXPENSE_ENTRY_TEMPLATE.xlsx`}
                      target="blank"
                      title="Download Expense Template"
                      download=""
                      className="btn border print"
                      disabled={!canAddExpense}
                    >
                      <DownloadIcon />
                    </a>

                    {canAddExpense && <><Link to={"/expenses-entry"} className="btn btn-outline-primary" style={{ lineHeight: 2 }}> Single Mode </Link><ExpenseSettings mode={'Multi Mode'} /></>}
                  </div>
                </div>


                <FormikProvider value={formik}>
                  <Form noValidate className="">
                    <div className="selected-data-area">
                      <div className="table-holder">


                        <FieldArray
                          name="expenses"
                          render={(arrayHelpers) => (
                            <>
                              <Table
                                responsive
                                borderless
                                hover
                                striped
                                className="product-table text-nowrap with-large-textarea"
                              >
                                <thead>
                                  <tr>
                                    <th />
                                    <th />
                                    <th>Account</th>
                                    <th>Account Title</th>
                                    <th>Currency</th>
                                    <th>Amount</th>
                                    <th>By</th>
                                    <th>From</th>
                                    <th>Particulers</th>

                                    <th>Job Account</th>
                                    <th>Pay to the order of</th>
                                    <th>Remark</th>

                                    <th>Reciept/Ref</th>
                                    <th>Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.expenses.map((el, index) => (
                                    <React.Fragment key={index}>
                                      <tr onKeyUp={e => e.key === 'Enter' && arrayHelpers.push({ ...formik.initialValues.expenses[0], Ref: nanoid(10, "number") })}>
                                        <td> {canRemoveExpense &&
                                          <Button
                                            type="button"
                                            variant=""
                                            onClick={() => handleRemove(index)}
                                          >
                                            <DeleteIcon />
                                          </Button>}
                                        </td>

                                        <td>
                                          {index + 1}
                                        </td>

                                        <td>
                                          <InputGroup className="flex-nowrap">
                                            <Form.Control
                                              name={`expenses[${index}].Account`}
                                              value={formik.values.expenses[index].Account}
                                              onChange={formik.handleChange}
                                            />
                                            <Popover
                                              isOpen={formik.values.expenses[index].showExpensesPopover}
                                              onClickOutside={() => formik.setFieldValue(`expenses[${index}].showExpensesPopover`, false)}
                                              content={() => (
                                                <ExpensesSelector Type="EXPENSES" handleSelectedExpense={(expense) => {
                                                  formik.setFieldValue(`expenses[${index}].Account`, expense.AccountID)
                                                  formik.setFieldValue(`expenses[${index}].AccountTitle`, expense.Description)
                                                  formik.setFieldValue(`expenses[${index}].Type`, expense.Type)
                                                  formik.setFieldValue(`expenses[${index}].showExpensesPopover`, false)
                                                }} />
                                              )}
                                              position="bottom"
                                            >
                                              <InputGroup.Text onClick={() => formik.setFieldValue(`expenses[${index}].showExpensesPopover`, true)}>
                                                <MagnifyIcon />
                                              </InputGroup.Text>
                                            </Popover>
                                          </InputGroup>

                                          <ErrorMessage
                                            component="span"
                                            className="text-danger"
                                            name={`expenses[${index}].Account`}
                                          />
                                        </td>
                                        <td>
                                          <Form.Control
                                            name={`expenses[${index}].AccountTitle`}
                                            value={formik.values.expenses[index].AccountTitle}
                                            onChange={formik.handleChange}
                                            readOnly
                                          />
                                        </td>
                                        <td>
                                          <Form.Group>
                                            <Select
                                              classNamePrefix="form-select"
                                              placeholder="Select Currency"
                                              isSearchable={false}
                                              options={currenciesOptions}
                                              value={currenciesOptions.find(
                                                (el) => el.value === formik.values.expenses[index].currency
                                              )}
                                              onChange={({ value }) =>
                                                formik.setFieldValue(`expenses[${index}].currency`, value)
                                              }
                                              // isDisabled={!canAddExpense}
                                              menuPosition="fixed"
                                              isDisabled={true}
                                            />
                                          </Form.Group>

                                          {formik.values.expenses[index].currency &&
                                            formik.values.expenses[index].currency !==
                                            generalSettings?.prevailingCurrency ? (
                                            <Form.Group>
                                              <CurrencyCustomInput
                                                currencySymbol={"Ex. Rate"}
                                                name="conversionAmount"
                                                value={formik.values.expenses[index].conversionAmount}
                                                onValueChange={(value, name) => {
                                                  formik.setFieldValue(`expenses[${index}].conversionAmount`, value);
                                                }}
                                                placeholder="0.00"
                                              />
                                            </Form.Group>
                                          ) : null}
                                        </td>
                                        <td> <CurrencyCustomInput
                                          currencySymbol={currencySymbol}
                                          name={`expenses[${index}].Amount`}
                                          value={formik.values.expenses[index].Amount}
                                          onValueChange={(value, name) => {
                                            formik.setFieldValue(name, value);
                                          }}
                                        />
                                          <ErrorMessage
                                            component="span"
                                            className="text-danger"
                                            name={`expenses[${index}].Amount`}
                                          />
                                        </td>
                                        <td>
                                          {formik.values.expenses[index]?.requisitioNpaymentType !== "Advance" ? (<>
                                            <Select
                                              classNamePrefix="form-select"
                                              menuPlacement="top"
                                              placeholder="Choose method"
                                              isSearchable={false}
                                              key={formik.values.expenses[index]
                                                .PaymentType}
                                              value={paymentMethod.find(
                                                (el) =>
                                                  el.value ===
                                                  formik.values.expenses[index]
                                                    .PaymentType
                                              )}
                                              options={paymentMethod}
                                              onChange={({ value }) => {
                                                formik.setFieldValue(
                                                  `expenses[${index}].PaymentType`,
                                                  value
                                                );
                                                formik.setFieldValue(
                                                  `expenses[${index}].From`,
                                                  ""
                                                );
                                              }}
                                              getOptionLabel={(el) => (
                                                <div className="label-with-icon d-flex gap-2 align-items-center">
                                                  <span>{el.icon}</span>{" "}
                                                  <span className="fw-5">
                                                    {el.label}
                                                  </span>
                                                </div>
                                              )}
                                              menuPosition="fixed"

                                            />
                                            <ErrorMessage
                                              component="span"
                                              className="text-danger"
                                              name={`expenses[${index}].PaymentType`}
                                            /></>) : 'Advanced Payment'}
                                        </td>
                                        <td>
                                          {formik.values.expenses[index]?.requisitioNpaymentType !== "Advance" ? (<>
                                            <Select
                                              classNamePrefix="form-select"
                                              value={resolveFromOptions(
                                                formik.values.expenses[index]
                                                  .PaymentType, formik.values.expenses[index].currency
                                              ).find(
                                                (el) =>
                                                  el.value ===
                                                  formik.values.expenses[index]
                                                    .From
                                              )}
                                              options={resolveFromOptions(
                                                formik.values.expenses[index]
                                                  .PaymentType, formik.values.expenses[index].currency
                                              )}
                                              onChange={({ value }) =>
                                                formik.setFieldValue(
                                                  `expenses[${index}].From`,
                                                  value
                                                )
                                              }
                                              key={
                                                formik.values.expenses[index]
                                                  ?.PaymentType
                                              }
                                              menuPosition="fixed"

                                            />
                                            <ErrorMessage
                                              component="span"
                                              className="text-danger"
                                              name={`expenses[${index}].From`}
                                            />
                                          </>) : '...'}
                                        </td>
                                        <td>
                                          <Form.Control
                                            className="h-auto col-6 table-textarea"
                                            as="textarea"
                                            name={`expenses[${index}].Particulers`}
                                            value={formik.values.expenses[index].Particulers}
                                            onChange={formik.handleChange}
                                            rows={3}
                                            placeholder="Enter Particulers..."
                                          /></td>

                                        <td>
                                          <InputGroup className="flex-nowrap">
                                            <Form.Control
                                              name={``}
                                              placeholder="Select Drum Account"
                                              value={`${formik.values.expenses[index]?.jobAccountID ||
                                                "..."} / ${formik.values.expenses[index]?.jobAccountDescription ||
                                                "..."}`}
                                              onChange={() => { }}
                                              readOnly
                                            />
                                            <Popover
                                              isOpen={formik.values.expenses[index]?.showJournalPopover}
                                              onClickOutside={() => {
                                                formik.setFieldValue(`expenses[${index}].showJournalPopover`, false)
                                              }}
                                              content={() => (
                                                <ExpensesSelector
                                                  handleSelectedExpense={(account) => {
                                                    formik.setFieldValue(`expenses[${index}].jobAccountID`, account?.AccountID);
                                                    formik.setFieldValue(`expenses[${index}].jobAccountDescription`, account?.Description);
                                                    formik.setFieldValue(`expenses[${index}].showJournalPopover`, false)
                                                  }}
                                                  usage={"chart of accounts"}
                                                  DetailType="JOB"
                                                />
                                              )}
                                              position="bottom"
                                            >
                                              <InputGroup.Text
                                                onClick={() => {
                                                  formik.setFieldValue(`expenses[${index}].showJournalPopover`, true)
                                                }}
                                              >
                                                <MagnifyIcon />
                                              </InputGroup.Text>
                                            </Popover>
                                          </InputGroup>
                                        </td>

                                        <td>
                                          <Select
                                            classNamePrefix="form-select"
                                            options={data.vendors}
                                            value={data.vendors.find(
                                              (el) => el.value === formik.values.expenses[index].payTo
                                            )}
                                            onChange={({ value }) =>
                                              formik.setFieldValue(`expenses[${index}].payTo`, value)
                                            }
                                            isSearchable
                                            menuPosition="fixed"
                                          />
                                        </td>

                                        <td>
                                          <Form.Control
                                            className="h-auto col-6 table-textarea"
                                            as="textarea"
                                            name={`expenses[${index}].Remark`}
                                            value={formik.values.expenses[index].Remark}
                                            onChange={formik.handleChange}
                                            rows={3}
                                            placeholder="Enter Remark..."
                                          />
                                        </td>

                                        <td> <Form.Control
                                          name={`expenses[${index}].Ref`}
                                          value={formik.values.expenses[index].Ref}
                                          onChange={formik.handleChange}
                                        />
                                          <ErrorMessage
                                            component="span"
                                            className="text-danger"
                                            name={`expenses[${index}].Ref`}
                                          />
                                        </td>
                                        <td>
                                          <Popover
                                            reposition={false}
                                            isOpen={formik.values.expenses[index].datePopoverOpened}
                                            onClickOutside={() => { }}
                                            align="end"
                                            padding={10}
                                            content={() => (
                                              <Datetime
                                                dateFormat="MM DD, YYYY"
                                                className="date-picker-2"
                                                timeFormat={false}
                                                closeOnSelect={true}
                                                closeOnClickOutside={true}
                                                name="salesDate"
                                                inputProps={{
                                                  className: `date-input form-control ${formik.values.expenses[index].salesDate && !!formik.values.expenses[index].salesDate
                                                    ? "is-invalid"
                                                    : ""
                                                    }`,
                                                  placeholder: "Select date",
                                                  readOnly: true,
                                                }}
                                                input={false}
                                                value={formik.values.expenses[index].salesDate}
                                                onChange={(date) => {
                                                  formik.setFieldValue(`expenses[${index}].salesDate`, date, true);
                                                  formik.setFieldValue(`expenses[${index}].datePopoverOpened`, false);
                                                }}
                                                onBlur={() => formik.setFieldTouched("salesDate", true)}
                                              />
                                            )}
                                          >
                                            <DatePickerCustomInput
                                              onClick={() => formik.setFieldValue(`expenses[${index}].datePopoverOpened`, !formik.values.expenses[index].datePopoverOpened)}
                                              value={convertMomentDate(formik.values.expenses[index].salesDate)}
                                            />
                                          </Popover>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                                </tbody>
                              </Table>
                              {canAddExpense && <Button
                                onClick={() =>
                                  arrayHelpers.push({ ...formik.initialValues.expenses[0], Ref: nanoid(10, "number") })
                                }
                                variant=""
                                type="button"
                                className="btn text-primary my-3 d-flex align-items-center gap-2"
                              >
                                <AddCircleIcon />
                                Add Account
                              </Button>}</>
                          )}

                        />
                      </div>

                    </div></Form>
                </FormikProvider>
              </div>

              <div className="d-flex justify-content-between total-info">
                <div />
                <div>
                  <Button
                    onClick={() => formik.submitForm()}
                    disabled={postExpenseMutation.isLoading}
                    type="button"
                    className="py-3 px-5"
                  >
                    Post Expenses
                  </Button>
                </div>
              </div>

            </section>

          </div>
        </div>

        {/*   Modals */}

        <ModalLoader show={postExpenseMutation.isLoading || isLoading} />
      </main >
    </IsPrivileged >
  );
}
