import { Modal, Form, Button, Row } from "react-bootstrap";
import * as yup from "yup";
import { ErrorMessage, FieldArray, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce, useUnits } from "../../utils/hooks";
import NumberCustomInput from "./../utils/NumberCustomInput";
import CurrencyCustomInput from "./../utils/CurrencyCustomInput";
import {
  amountTypes,
  customerTypeOptions,
  qtyFormat,
  Units,
} from "../../utils/helpers";
import { appSettings } from "../../config";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./../ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { allAcountDetailIDs, fetchActionsUtil } from "../../utils/helpers";
import { isEmpty, uniqBy } from "lodash";
import CreatableSelect from "react-select/creatable";

const itemsStatus = [
  {
    label: "Unprocessed",
    value: "Unprocessed",
  },
  {
    label: "Processed",
    value: "Processed",
  },
];

export default function UpdateWarehouseItemPriceModal({
  showUpdatePriceModal,
  selectedItem,
  setSelectedItem,
  setShowUpdatePriceModal,
  refetch,
}) {
  const backendUrl = useBackendUrl();

  const [accountsDetails, setaccountsDetails] = useState([]);
  const [accountses, setAccountses] = useState([]);
  const [accountDescribtion, setAccountDescribtion] = useState(null);
  const [prevType, setPrevType] = useState("");

  const { Units, getUnitLabelByValue } = useUnits();

  const itemMeasurements = useStoreState((state) =>
    state.itemMeasurements.map((el) => ({ label: el.Size, value: el.Size }))
  );

  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const generalSettings = useStoreState((state) => state?.generalSettings);

  //   console.log(selectedItem);

  const setUpUI = async (department) => {
    let response = await fetch(
      `${backendUrl}/api/items/set-up?withVendorBalance=false&Bar_Code=${
        selectedItem.Bar_Code
      }`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    const { storage } = await fetchActionsUtil(
      `${backendUrl}/api/items/storage-location`,
      "GET"
    );

    const cat = await fetchActionsUtil(
      `${backendUrl}/api/items/category?`,
      "GET"
    );

    const pro = await fetchActionsUtil(
      `${backendUrl}/api/items/product-name`,
      "GET"
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = data?.vendors
      .filter((el) => el.Vendor_ID)
      .map((el) => ({
        ...el,
        label: el.CompanyName,
        value: el.Vendor_ID,
      }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = cat.items.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = pro.items.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.customerTypes = uniqBy(
      [
        ...data.customerTypes
          .filter(
            (el) =>
              !customerTypeOptions.find(
                (defaultType) => defaultType.value === el.TransType
              )
          )
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );

    if (!isEmpty(data?.priceByCustomerTypeForItem)) {
      formik.setFieldValue(
        "priceByCustomerType",
        data?.priceByCustomerTypeForItem
      );
    }

    data.storage = storage?.map((el) => ({
      value: el.storageID,
      label: el.name,
    }));

    // console.log(data);
    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
      customerTypes: [],
    },
    refetch: refetchSetup,
    isFetching,
  } = useQuery(["queryActions.ITEMS_SETUP"], () => setUpUI(), {
    enabled: true,
    cacheTime: 1000 * 60 * 60,
  });

  const initialValues = {
    UnitPrice: 0, // walk / retail customer
    Vat_5: 0, // distributor
    Tax: 0, // unit price in pieces in gigc, wholesale in normal invex
    Unit: "Each",
    Size: "",

    //--------------
    Margin: 0,
    Incentive: 0,
    UnitCost: 0,
    qty: 0,

    //-------------
    pcsPriceDistributor: 0,

    accountSelect: "",
    accountName: "",
    savingType: "",
    DetailType: "",
    Item_Name: "",

    markUp: 0,
    markUpType: "Fixed",
    markUpPercentage: "",

    marginType: "",
    marginPercentage: "",
    incentiveType: "",
    incentivePercentage: "",

    Discount: 0,
    discountType: "Fixed",
    discountPercentage: 0,

    priceByCustomerType: [
      {
        TransType: "",
        UnitPrice: 0,
        UnitPricePcs: 0,
        Bar_Code: selectedItem.Bar_Code,
      },
    ],
    currentMarketPrice: 0,
    Cat_Name: "",
    Product_Name: "",
    Reorder_Level: 0,
    ItemStatus: "",
    MaximumLevel: 0,
    restaurantMenu: false,
    Account_New_Name: "",
  };
  const schema = yup.object().shape({
    UnitPrice: yup.string().required("required"),
    Tax: yup.string().required("required"),
    Vat_5: yup.string().required("required"),
    qty: yup.string().required("required"),
    Item_Name: yup.string().required("required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (
        wareshouseLocal?.cater === "Assets" ||
        wareshouseLocal?.cater === "non-stock"
      ) {
        if (!values.accountSelect) {
          return formik.setFieldError("accountSelect", `Select Account`);
        }
        if (!values.DetailType) {
          return formik.setFieldError(
            "DetailType",
            `Select Account Detail Type`
          );
        }
        if (!values.accountName) {
          return formik.setFieldError("accountName", `Map to account`);
        }

        if (values.accountSelect === "CreateAccount") {
          if (values.Account_New_Name === "") {
            return formik.setFieldError(
              "Account_New_Name",
              `Account New Name required`
            );
          }

          if (accountDescribtion !== values.Account_New_Name) {
            values.accountName = await getUniqueAccountId("FIXED ASSET");
          }
        }
      }

      updatePriceMutation.mutate({
        ...values,
        Warehouse: selectedItem.Warehouse,
        Bar_Code: selectedItem.Bar_Code,
      });
    },
  });

  const getAccountDetailType = async (desc) => {
    const acc = await fetchActionsUtil(
      `${backendUrl}/api/accounts/accountDetailType/${desc}`,
      "GET"
    );

    const account = acc.accountDes.map((el) => ({
      value: el.AccountID,
      label: el.Description,
    }));

    setAccountses(account);
  };

  const setUp = async () => {
    if (
      wareshouseLocal?.cater === "Assets" ||
      wareshouseLocal?.cater === "non-stock"
    ) {
      const detailsAccount = await fetchActionsUtil(
        `${backendUrl}/api/accounts/accountDesc/FIXED ASSET`,
        "GET"
      );

      const currentAsset = await fetchActionsUtil(
        `${backendUrl}/api/accounts/accountDesc/CURRENT ASSET`,
        "GET"
      );

      const joins = [...detailsAccount.accountDes, ...currentAsset.accountDes];

      const details = [...new Set(joins?.map((d) => d.AccountDesc))].map(
        (el) => ({
          value: el,
          label: el,
        })
      );
      setaccountsDetails(details);
      setAccountDescribtion(selectedItem?.accountListAssets?.Description);
      selectedItem?.DetailType &&
        getAccountDetailType(selectedItem?.DetailType);
      setPrevType(selectedItem?.accountSelect);
    }

    formik.setValues({
      UnitPrice: selectedItem?.UnitPrice,
      Tax: selectedItem?.Tax,
      Vat_5: selectedItem?.Vat_5,
      Unit: selectedItem?.Product_Model,
      Size: selectedItem?.Item_Desc,
      qty: selectedItem?.Quantity,

      //----------------
      Margin: selectedItem?.Margin,
      Incentive: selectedItem?.Incentive,
      UnitCost: selectedItem?.UnitCost,
      //-----------------
      pcsPriceDistributor: selectedItem?.pcsPriceDistributor || 0,

      accountSelect: selectedItem?.accountSelect,
      accountName: selectedItem?.accountName,
      savingType: selectedItem?.savingType,
      DetailType: selectedItem?.DetailType,
      Item_Name: selectedItem?.Item_Name,

      // ---------------------

      markUp: selectedItem?.markUp,
      markUpType: selectedItem?.markUpType,
      markUpPercentage: selectedItem?.markUpPercentage,

      marginType: selectedItem?.marginType,
      marginPercentage: selectedItem?.marginPercentage,
      incentiveType: selectedItem?.incentiveType,
      incentivePercentage: selectedItem.incentivePercentage,

      Discount: selectedItem.Discount,
      discountType: selectedItem.discountType,
      discountPercentage: selectedItem.discountPercentage,

      priceByCustomerType: formik.values.priceByCustomerType,
      currentMarketPrice: selectedItem?.currentMarketPrice,
      Product_Name: selectedItem.Product_Name,
      Cat_Name: selectedItem.Cat_Name,
      Reorder_Level: selectedItem.Reorder_Level,
      MaximumLevel: selectedItem.MaximumLevel,
      ItemStatus: selectedItem?.ItemStatus,
      restaurantMenu: selectedItem?.restaurantMenu || false,
      Account_New_Name: selectedItem?.accountListAssets?.Description
        ? selectedItem?.accountListAssets?.Description
        : "",
    });
  };

  useEffectOnce(() => {
    setUp();
  });

  const updatePrice = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/update-price`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const updatePriceMutation = useMutation((payload) => updatePrice(payload), {
    onSuccess: ({ message, data }) => {
      refetch();
      toast.success(message);
      setSelectedItem(null);
      setShowUpdatePriceModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const addItemMeasurement = async () => {
    const newSize = await ItemSizeDialog();
    if (newSize) {
      formik.setFieldValue("Size", newSize.Size);
      setItemMeasurements([...itemMeasurements, newSize]);
    }
  };

  const getUniqueAccountId = async (name) => {
    let rangers = allAcountDetailIDs.filter((item) => {
      if (item.data.some((d) => d === name)) {
        return [item.num1, item.num2];
      }
    });
    let id = "";
    const res = await fetchActionsUtil(
      `${backendUrl}/api/accounts/generate-account-id`,
      "POST",
      "",
      { num1: rangers[0].num1, num2: rangers[0].num2 }
    );
    id = res.id;

    if (id === "") {
      getUniqueAccountId(name);
    } else {
      return id;
    }
  };

  const chooseCreateAccount = async () => {
    formik.setFieldValue("accountSelect", "CreateAccount");
    let id = await getUniqueAccountId("FIXED ASSET");
    formik.setFieldValue("accountName", id);
  };

  const chooseMapToAccount = async () => {
    formik.setFieldValue("accountSelect", "MapToAccount");
    formik.setFieldValue("accountName", "");
  };

  //Incentive & Margin
  //   useEffect(() => {
  //     if (appSettings.hasIncentive) {
  //       const UnitPrice = currency(formik.values.UnitCost)
  //         .add(formik.values.Incentive)
  //         .add(formik.values.Margin).value;

  //       formik.setFieldValue("UnitPrice", UnitPrice);
  //       formik.setFieldValue("Tax", UnitPrice);
  //       formik.setFieldValue("Vat_5", UnitPrice);
  //     }
  //   }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  const showAccountSections = () => {
    let show = null;
    show = (
      <>
        <Form.Check
          inline
          label={
            accountDescribtion ? "Map To Different Account" : "Map To Account"
          }
          value="MapToAccount"
          name="accountSelect"
          type="radio"
          checked={formik.values?.accountSelect === "MapToAccount"}
          onChange={chooseMapToAccount}
          isInvalid={
            formik.touched.accountSelect && !!formik.errors.accountSelect
          }
        />
        <Form.Check
          inline
          label="Create Account"
          value="CreateAccount"
          name="accountSelect"
          type="radio"
          onChange={chooseCreateAccount}
          checked={formik.values?.accountSelect === "CreateAccount"}
          isInvalid={
            formik.touched.accountSelect && !!formik.errors.accountSelect
          }
        />
      </>
    );
    // }

    return show;
  };

  //Incentive & Margin
  useEffect(() => {
    if (formik.values.markUpType === "Percentage") {
      formik.setFieldValue(
        "markUp",
        currency(formik.values.markUpPercentage)
          .divide(100)
          .multiply(formik.values.UnitCost).value
      );
    }

    if (formik.values.incentiveType === "Percentage") {
      formik.setFieldValue(
        "Incentive",
        currency(formik.values.incentivePercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }

    if (formik.values.marginType === "Percentage") {
      formik.setFieldValue(
        "Margin",
        currency(formik.values.marginPercentage)
          .divide(100)
          .multiply(formik.values.markUp).value
      );
    }
  }, [
    formik.values.UnitCost,
    formik.values.marginPercentage,
    formik.values.incentivePercentage,
    formik.values.marginType,
    formik.values.incentiveType,
    formik.values.markUp,
    formik.values.markUpType,
    formik.values.markUpPercentage,
  ]);

  useEffect(() => {
    if (appSettings.hasIncentive) {
      const UnitPrice = currency(formik.values.UnitCost)
        .add(formik.values.Incentive)
        .add(formik.values.Margin).value;

      formik.setFieldValue("UnitPrice", UnitPrice);
      formik.setFieldValue("Tax", UnitPrice);
      formik.setFieldValue("Vat_5", UnitPrice);
    }
  }, [formik.values.UnitCost, formik.values.Margin, formik.values.Incentive]);

  return (
    <Modal
      show={showUpdatePriceModal}
      onHide={() => {
        setSelectedItem(null);
        setShowUpdatePriceModal(false);
      }}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Edit {selectedItem?.Item_Name}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {wareshouseLocal?.cater === "Assets" ||
            wareshouseLocal?.cater === "non-stock" ? (
              <>
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-3">Account</Form.Label>
                  <div className="d-flex gap-3 justify-content-between w-100">
                    {accountDescribtion ? (
                      <div>
                        <span>
                          {" "}
                          <b>Asset Account :</b>{" "}
                        </span>{" "}
                        <span>{accountDescribtion}</span>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
                {showAccountSections()}
                {/*  */}
                <div className="row">
                  <Form.Group className="col-6 mb-3">
                    <Form.Label className="mb-1">
                      Account Detail Type
                    </Form.Label>
                    <Select
                      classNamePrefix={"form-select"}
                      value={accountsDetails?.find(
                        (el) => el.value === formik.values.DetailType
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("DetailType", value);
                        getAccountDetailType(value);
                      }}
                      options={accountsDetails}
                    />
                    {formik.touched.DetailType && !!formik.errors.DetailType ? (
                      <p className="custom-invalid-feedback">
                        {formik.errors.DetailType}
                      </p>
                    ) : null}
                  </Form.Group>

                  {formik.values.accountSelect === "CreateAccount" ? (
                    <div className="mb-3 col-6">
                      <Form.Group className="mb-3 pb-2">
                        <Form.Label className="mb-1">
                          Account New Name
                        </Form.Label>
                        <Form.Control
                          className=""
                          type="text"
                          placeholder="Enter Account New Name"
                          name="Account_New_Name"
                          value={formik.values.Account_New_Name}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.Account_New_Name &&
                            !!formik.errors.Account_New_Name
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.Account_New_Name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  ) : (
                    <div className="mb-3 col-6">
                      <Form.Label className="mb-1">
                        Map to GL account
                      </Form.Label>
                      <div className="form-group">
                        <Select
                          closeMenuOnSelect={true}
                          value={accountses?.find(
                            (el) => el.value === formik.values.accountName
                          )}
                          onChange={(selected) => {
                            formik.setFieldValue("accountName", selected.value);
                          }}
                          options={accountses}
                        />
                        <span className="form-control-feedback text-danger">
                          {formik.errors.accountName}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : null}
            {/*  */}

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>
            {appSettings.hasIncentive && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>UnitCost</Form.Label>
                  <CurrencyCustomInput
                    type="text"
                    placeholder="Enter Unit Cost"
                    name="UnitCost"
                    value={formik.values.UnitCost}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.UnitCost && !!formik.errors.UnitCost
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.UnitCost}
                  </Form.Control.Feedback>
                </Form.Group>
                {appSettings.hasIncentive ? (
                  <>
                    <Row>
                      <Form.Group className="col-md-4 mb-3 pb-2">
                        <Form.Label className="mb-1">Mark Up Type</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={amountTypes}
                          value={amountTypes.find(
                            (el) => el.value === formik.values.markUpType
                          )}
                          onChange={(selected) =>
                            formik.setFieldValue("markUpType", selected.value)
                          }
                        />
                      </Form.Group>

                      {formik.values.markUpType === "Percentage" && (
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Mark Up (%)</Form.Label>
                          <Form.Control
                            type="number"
                            name="markUpPercentage"
                            value={formik.values.markUpPercentage}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="col-md-5 mb-3 pb-2">
                        <Form.Label className="mb-1">Mark Up</Form.Label>
                        <CurrencyCustomInput
                          name="markUp"
                          placeholder="0.00"
                          value={formik.values.markUp}
                          onValueChange={(value, name) => {
                            formik.setFieldValue(name, value);
                          }}
                          isInvalid={
                            formik.touched.markUp && !!formik.errors.markUp
                          }
                          onBlur={() => formik.setFieldTouched("markUp", true)}
                          disabled={formik.values.markUpType === "Percentage"}
                        />
                        {formik.touched.markUp && !!formik.errors.markUp ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.markUp}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="col-md-4 mb-3 pb-2">
                        <Form.Label className="mb-1">Profit Type</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={amountTypes}
                          value={amountTypes.find(
                            (el) => el.value === formik.values.marginType
                          )}
                          onChange={(selected) =>
                            formik.setFieldValue("marginType", selected.value)
                          }
                        />
                      </Form.Group>

                      {formik.values.marginType === "Percentage" && (
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1">Profit (%)</Form.Label>
                          <Form.Control
                            type="number"
                            name="marginPercentage"
                            value={formik.values.marginPercentage}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="col-md-5 mb-3 pb-2">
                        <Form.Label className="mb-1">Profit</Form.Label>
                        <CurrencyCustomInput
                          name="Margin"
                          placeholder="0.00"
                          value={formik.values.Margin}
                          onValueChange={(value, name) => {
                            formik.setFieldValue("Margin", value);
                          }}
                          isInvalid={
                            formik.touched.Margin && !!formik.errors.Margin
                          }
                          onBlur={() => formik.setFieldTouched("Margin", true)}
                          disabled={formik.values.marginType === "Percentage"}
                        />
                        {formik.touched.Margin && !!formik.errors.Margin ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.Margin}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Row>
                    <Row>
                      <Form.Group className="col-md-4 mb-3 pb-2">
                        <Form.Label className="mb-1">Incentive Type</Form.Label>
                        <Select
                          classNamePrefix={"form-select"}
                          options={amountTypes}
                          value={amountTypes.find(
                            (el) => el.value === formik.values.incentiveType
                          )}
                          onChange={(selected) =>
                            formik.setFieldValue(
                              "incentiveType",
                              selected.value
                            )
                          }
                        />
                      </Form.Group>

                      {formik.values.incentiveType === "Percentage" && (
                        <Form.Group className="col-md-3 mb-3 pb-2">
                          <Form.Label className="mb-1 text-nowrap">
                            Incentive (%)
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="incentivePercentage"
                            value={formik.values.incentivePercentage}
                            onChange={formik.handleChange}
                          />
                        </Form.Group>
                      )}

                      <Form.Group className="col-md-5 mb-3 pb-2">
                        <Form.Label className="mb-1">Incentive</Form.Label>
                        <CurrencyCustomInput
                          name="Incentive"
                          placeholder="0.00"
                          value={formik.values.Incentive}
                          onValueChange={(value, name) => {
                            formik.setFieldValue("Incentive", value);
                          }}
                          isInvalid={
                            formik.touched.Incentive &&
                            !!formik.errors.Incentive
                          }
                          onBlur={() =>
                            formik.setFieldTouched("Incentive", true)
                          }
                          disabled={
                            formik.values.incentiveType === "Percentage"
                          }
                        />
                        {formik.touched.Incentive &&
                        !!formik.errors.Incentive ? (
                          <span className="custom-invalid-feedback">
                            {formik.errors.Incentive}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Row>
                  </>
                ) : null}
              </>
            )}

            <Form.Group className="mb-3">
              <Form.Label>
                {selectedItem.Product_Model === "Tons"
                  ? `${getUnitLabelByValue(`Tonage`)} Price`
                  : `UnitPrice (Retail / Walk In)`}
              </Form.Label>

              <CurrencyCustomInput
                type="text"
                name="UnitPrice"
                value={formik.values.UnitPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={
                  formik.touched.UnitPrice && !!formik.errors.UnitPrice
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.UnitPrice}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Form.Group className="col-md-4 mb-3 pb-2">
                <Form.Label className="mb-1">Discount Type</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={amountTypes}
                  value={amountTypes.find(
                    (el) => el.value === formik.values.discountType
                  )}
                  onChange={(selected) => {
                    formik.setFieldValue("discountType", selected.value);
                    if (selected.value === "None") {
                      formik.setFieldValue("discountPercentage", 0);
                      formik.setFieldValue("Discount", 0);
                    }
                  }}
                />
              </Form.Group>

              {formik.values.discountType === "Percentage" ? (
                <Form.Group className="col-md-3 mb-3 pb-2">
                  <Form.Label className="mb-1">Discount (%)</Form.Label>
                  <Form.Control
                    type="number"
                    name="discountPercentage"
                    value={formik.values.discountPercentage}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              ) : (
                <Form.Group className="col-md-5 mb-3 pb-2">
                  <Form.Label className="mb-1">Discount</Form.Label>
                  <CurrencyCustomInput
                    name="Discount"
                    placeholder="0.00"
                    value={formik.values.Discount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.Discount && !!formik.errors.Discount
                    }
                    onBlur={() => formik.setFieldTouched("Discount", true)}
                  />
                </Form.Group>
              )}
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>
                {selectedItem?.Product_Model !== "Tons"
                  ? `UnitPrice (Wholesale)`
                  : `Pieces Price`}
              </Form.Label>
              <CurrencyCustomInput
                type="text"
                name="Tax"
                value={formik.values.Tax}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Tax && !!formik.errors.Tax}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.UnitPrice}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>
                {selectedItem?.Product_Model !== "Tons"
                  ? `UnitPrice (Distributor)`
                  : `Tonage Price (Distributor)`}
              </Form.Label>
              <CurrencyCustomInput
                type="text"
                name="Vat_5"
                value={formik.values.Vat_5}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.Vat_5 && !!formik.errors.Vat_5}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Vat_5}
              </Form.Control.Feedback>
            </Form.Group>

            {selectedItem?.Product_Model === "Tons" && (
              <Form.Group className="mb-3">
                <Form.Label>Pieces Price (Distributor) </Form.Label>
                <CurrencyCustomInput
                  type="text"
                  name="pcsPriceDistributor"
                  value={formik.values.pcsPriceDistributor}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.pcsPriceDistributor &&
                    !!formik.errors.pcsPriceDistributor
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.pcsPriceDistributor}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group className="col mb-3 pb-2 border rounded">
              <Form.Label className="mb-1 p-3">
                Prices By Customer Type
              </Form.Label>

              <FieldArray
                name="priceByCustomerType"
                render={(arrayHelpers) => (
                  <div>
                    {formik.values.priceByCustomerType?.map((el, index) => (
                      <div key={index} className="mb-2">
                        <div className="row align-items-center px-3">
                          <div className="col-md-6 pe-0">
                            <Form.Label className="mb-1">
                              Customer Type
                            </Form.Label>

                            <Select
                              classNamePrefix={"form-select"}
                              placeholder="Customer Type"
                              options={data.customerTypes}
                              value={data.customerTypes.find(
                                (el) =>
                                  el.value ===
                                  formik.values.priceByCustomerType[index]
                                    .TransType
                              )}
                              onChange={({ value }) =>
                                formik.setFieldValue(
                                  `priceByCustomerType[${index}].TransType`,
                                  value
                                )
                              }
                            />
                          </div>
                          <div className="col-md-5">
                            <Form.Label className="mb-1">
                              Unit Price{" "}
                              {getUnitLabelByValue(
                                ["Tons", "Pieces"].includes(formik.values.Unit)
                                  ? "Tons"
                                  : ""
                              )}
                            </Form.Label>
                            <CurrencyCustomInput
                              name={`priceByCustomerType[${index}].UnitPrice`}
                              value={
                                formik.values.priceByCustomerType[index]
                                  .UnitPrice
                              }
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0.00"
                            />
                          </div>
                          <div className="col-md-1 px-1">
                            <button
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              ✖
                            </button>
                          </div>

                          {["Tons", "Pieces"].includes(formik.values.Unit) && (
                            <div className="col-md-5">
                              <Form.Label className="mb-1 mt-2">
                                Unit Price Pieces
                              </Form.Label>
                              <CurrencyCustomInput
                                name={`priceByCustomerType[${index}].UnitPricePcs`}
                                value={
                                  formik.values.priceByCustomerType[index]
                                    .UnitPricePcs
                                }
                                onValueChange={(value, name) => {
                                  formik.setFieldValue(name, value);
                                }}
                                placeholder="0.00"
                              />
                            </div>
                          )}
                        </div>

                        <ErrorMessage
                          component="span"
                          className="text-danger mb-2"
                          name={`priceByCustomerType[${index}].TransType`}
                        />

                        <hr />
                      </div>
                    ))}
                    <div className="d-flex justify-content-end px-4 pb-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        title="Remove"
                        onClick={() =>
                          arrayHelpers.push({
                            TransType: "",
                            UnitPricePcs: 0,
                            UnitPrice: 0,
                            Bar_Code: selectedItem.Bar_Code,
                          })
                        }
                      >
                        + Add
                      </button>
                    </div>
                  </div>
                )}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Current Market Price</Form.Label>
              <CurrencyCustomInput
                type="text"
                name="currentMarketPrice"
                value={formik.values.currentMarketPrice}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
              />
            </Form.Group>

            <div className="row text-nowrap align-items-center">
              <Form.Group className="col-5 mb-3 pb-2">
                <Form.Label className="mb-1">Unit</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={Units}
                  value={Units.find((el) => el.value === formik.values.Unit)}
                  onChange={({ value }) => {
                    formik.setFieldValue("Unit", value);
                  }}
                />
                <Form.Control.Feedback type="invalid" />
              </Form.Group>

              {["Tons", "Pieces"].includes(formik.values.Unit) && (
                <>
                  <Form.Group className="col-6 mb-3 pb-2">
                    <Form.Label className="mb-1">Size</Form.Label>
                    <Select
                      classNamePrefix={`form-select`}
                      options={itemMeasurements}
                      value={itemMeasurements.find(
                        (el) => el.value === formik.values.Size
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Size", value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="col-2 pb-2 px-0">
                    <Button size="sm" onClick={() => addItemMeasurement()}>
                      + Add
                    </Button>
                  </Form.Group>
                </>
              )}

              {wareshouseLocal?.cater === "Assets" ||
              wareshouseLocal?.cater === "non-stock" ? (
                // <Form.Group className="mb-3">
                //   <Form.Label>Quantity</Form.Label>

                //   <Form.Control
                //     className=""
                //     name="qty"
                //     value={formik.values.qty}
                //     onChange={(e) => formik.setFieldValue("qty", e.target.value)}
                //     placeholder="Quantity"
                //     isInvalid={formik.touched.qty && !!formik.errors.qty}
                //   />
                //   <Form.Control.Feedback type="invalid">
                //     {formik.errors.qty}
                //   </Form.Control.Feedback>
                // </Form.Group>

                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label>Quantity</Form.Label>
                  <NumberCustomInput
                    placeholder="0"
                    name="qty"
                    value={formik.values.qty}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={formik.touched.qty && !!formik.errors.qty}
                    onBlur={() => formik.setFieldTouched("qty", true)}
                  />
                  {formik.touched.qty && !!formik.errors.qty ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.qty}
                    </span>
                  ) : null}
                </Form.Group>
              ) : null}
            </div>

            <div className="row">
              <Form.Group className="col mb-3 pb-2">
                <Form.Label className="mb-1">Product</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.products}
                  value={data?.products?.find(
                    (el) => el.value === formik.values.Product_Name
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Product_Name", value)
                  }
                />
                {formik.touched.Product_Name && formik.errors.Product_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Product_Name}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="col mb-3 pb-2">
                <Form.Label className="mb-1">Category</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.categories}
                  value={data?.categories?.find(
                    (el) => el.value === formik.values.Cat_Name
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Cat_Name", value)
                  }
                />
                {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Cat_Name}
                  </span>
                ) : null}
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Minimum Level</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Reorder Lvl"
                  name="Reorder_Level"
                  value={formik.values.Reorder_Level}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>

              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Maximum Level</Form.Label>
                <NumberCustomInput
                  type="text"
                  placeholder="Enter Maximum Level"
                  name="MaximumLevel"
                  value={formik.values.MaximumLevel}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>

              {generalSettings?.itemStatusSettings && (
                <Form.Group className="col-md-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Item Status</Form.Label>
                  <CreatableSelect
                    classNamePrefix={`form-select`}
                    options={itemsStatus}
                    value={itemsStatus?.find(
                      (el) => el.value === formik.values.ItemStatus
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("ItemStatus", value)
                    }
                  />
                  {formik.touched.ItemStatus && formik.errors.ItemStatus ? (
                    <span className="text-danger mt-2">{formik.errors}</span>
                  ) : null}
                </Form.Group>
              )}
            </div>

            <Form.Group className="col-6 mb-3 pb-2">
              <Form.Check
                type="switch"
                label="Restaurant Menu"
                name="restaurantMenu"
                className="custom-form-check mb-3"
                checked={formik.values.restaurantMenu}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Button
              disabled={updatePriceMutation.isLoading}
              variant="primary"
              className="w-100 p-2"
              type="submit"
            >
              {updatePriceMutation.isLoading ? "Please wait…" : "Save Changes"}
            </Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

/* export default function UpdateItemPriceModal(props) {
  return createConfirmation(confirmable(UpdatePrice))({ ...props });
}
 */
