import { cloneDeep, isEmpty, map, uniqBy } from "lodash";
import { forwardRef, Fragment, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import {
  convertMomentDate,
  convertToBaseCurrency,
  customerFullName,
  employeeFullName,
  scrollToElement,
  waitFor,
} from "../utils/helpers";
import CustomerSelectModal from "./CustomerSelectModal";
import {
  AddCircleIcon,
  CashSelectIcon,
  ChequeSelectIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DeleteIcon,
  DirectTransferSelectIcon,
  MoneyDollarIcon,
  NoSelectedItemIcon,
} from "./Icons";
import PageHeader from "./PageHeader";
import Datetime from "react-datetime";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { FieldArray, Formik, FormikProvider, useFormik } from "formik";
import moment from "moment";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsGovernmentInvexERP,
  useTaxOptions,
} from "../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import ModalLoader from "./utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "./SelectBankDialog";
import { queryActions } from "../utils/reactQueryActions";
import { last } from "lodash";
import * as yup from "yup";
import { first } from "lodash";
import ConfirmDialog from "./ConfirmDialogue";
import { appSettings } from "../config";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { useStoreState } from "easy-peasy";
import ReceiptDialog from "./ReceiptDialog";
import { IsPrivileged } from "./DisplayChildElement";
import { currenciesOptions, currenciesOptionsAll } from "../utils/currencies";
import { FilesAttachments } from "../utils/Attachments";
import { useLocation, useNavigate } from "react-router-dom";
import eventBus from "../utils/EventBus";

const paymentMethod = [
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },
  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card",
    value: "Credit/Debit Card",
  },
  /*  {
    icon: <CreditMemoSelectIcon />,
    label: "Credit Memo",
    value: "Credit Memo",
  }, */
  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
];

const TaxPicker = forwardRef(({
  newInstallmentBeforeTax,
  setNewInstallmentBeforeTax,
  newInstallment,
  setTaxes,
  setNewInstallment,
  setNewInstallmentAfterTax,
  newInstallmentAfterTax,
  paymentCurrency = "",
}, ref) => {
  const generalSettings = useStoreState((state) => state.generalSettings);
  const taxOptions = useTaxOptions();
  const formik = useFormik({
    initialValues: {
      taxes: [],
    },
  });

  // Calculate other taxValues
  const otherTaxValues = useMemo(() => {
    return formik.values.taxes
      .map((el) => {
        const taxType = taxOptions.find(
          (taxOption) => taxOption.value === el.taxType
        );
        return {
          ...el,
          taxValue: currency(newInstallmentBeforeTax, {
            precision: 4,
          }).multiply(taxType?.percentage).value,
        };
      })
      .filter((el) => el.taxType);
  }, [newInstallmentBeforeTax, formik.values.taxes, taxOptions]);

  useEffect(() => {
    setTaxes(otherTaxValues);
    setNewInstallment(
      currency(newInstallmentBeforeTax).value
      /* subtract(
        otherTaxValues.reduce(
          (sum, tax) => currency(sum).add(tax.taxValue).value,
          0
        )
      ) */
    );
    setNewInstallmentAfterTax(
      currency(newInstallmentBeforeTax).subtract(
        otherTaxValues.reduce(
          (sum, tax) => currency(sum).add(tax.taxValue).value,
          0
        )
      ).value
    );
  }, [otherTaxValues, newInstallmentBeforeTax]);


  const initialTaxes = (taxes) => {
    formik.setFieldValue("taxes", taxes)
  }

  // Expose the function to the parent
  useImperativeHandle(ref, () => ({
    initialTaxes
  }));

  return (
    <FormikProvider value={formik}>
      <Form noValidate className="">
        <Form.Group className="mb-3 d-flex gap-2 align-items-center">
          {!isEmpty(formik.values.taxes) && (
            <Form.Label className="m-0">Amount:</Form.Label>
          )}
          <CurrencyCustomInput
            currencySymbol={
              currenciesOptions.find(
                (curr) =>
                  curr.cc ===
                  (paymentCurrency
                    ? paymentCurrency
                    : generalSettings?.prevailingCurrency)
              )?.symbol
            }
            name="newInstallmentBeforeTax"
            placeholder="0.00"
            defaultValue=""
            value={newInstallmentBeforeTax}
            onValueChange={(value, name) => {
              setNewInstallmentBeforeTax(value);
            }}
          />{" "}
        </Form.Group>

        {/* Add more TAX */}
        <FieldArray
          name="taxes"
          render={(arrayHelpers) => (
            <>
              <div className="row">
                {formik.values.taxes.map((el, index) => (
                  <div className="d-flex gap-3 mb-3 align-items-bottom">
                    <Form.Group className="col-6">
                      {/*   <Form.Label>Tax</Form.Label> */}
                      <Select
                        classNamePrefix="form-select"
                        placeholder="Tax"
                        isSearchable={false}
                        value={taxOptions.find(
                          (el) =>
                            el.value === formik.values.taxes[index].taxType
                        )}
                        options={taxOptions}
                        onChange={({ value }) => {
                          formik.setFieldValue(
                            `taxes[${index}].taxType`,
                            value
                          );
                        }}
                        menuPosition="fixed"
                      />
                    </Form.Group>

                    <Form.Group className="col-6">
                      {/*  <Form.Label>
                        {formik.values.taxes[index].taxType}
                      </Form.Label> */}
                      <div className="d-flex">
                        <CurrencyCustomInput
                          currencySymbol={
                            currenciesOptions.find(
                              (curr) =>
                                curr.cc ===
                                (paymentCurrency
                                  ? paymentCurrency
                                  : generalSettings?.prevailingCurrency)
                            )?.symbol
                          }
                          value={
                            otherTaxValues.find(
                              (el) =>
                                el.taxType ===
                                formik.values.taxes[index].taxType
                            )?.taxValue || 0
                          }
                          onValueChange={(value, name) => { }}
                          placeholder="0.00"
                        />
                        <Button
                          title="Remove"
                          variant=""
                          type="button"
                          size="xs"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          ✖
                        </Button>
                      </div>
                    </Form.Group>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-content-start">
                <Button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      taxType: "",
                    })
                  }
                  variant="light-blue"
                  className="text-primary border"
                  size="sm"
                >
                  + Add Tax Deductions
                </Button>
              </div>
            </>
          )}
        />

        {!isEmpty(formik.values.taxes) && (
          <Form.Group className="my-3 d-flex gap-2 align-items-center">
            <Form.Label className="m-0">Amount After Tax Deductions:</Form.Label>
            <CurrencyCustomInput
              currencySymbol={
                currenciesOptions.find(
                  (curr) =>
                    curr.cc ===
                    (paymentCurrency
                      ? paymentCurrency
                      : generalSettings?.prevailingCurrency)
                )?.symbol
              }
              placeholder="0.00"
              defaultValue=""
              title={newInstallment}
              value={newInstallmentAfterTax}
              onValueChange={(value, name) => { }}
            />{" "}
          </Form.Group>
        )}
      </Form>
    </FormikProvider>
  );
});

function duePaymentLabel(el) {
  const currencyText = el?.Payment_Due_Transaction?.currency;
  const amountDue =
    el?.Payment_Due_Transaction?.currency !== el?.prevailingCurrency
      ? currency(el.AmountDue).divide(
        el?.Payment_Due_Transaction?.conversionAmount
      ).value
      : el.AmountDue;

  return `${el.Trans_ID} -  ${el?.Payment_Due_Transaction?.ShipTo} - ${currency(
    amountDue,
    {
      symbol: currenciesOptionsAll.find(
        (curr) =>
          curr.cc === (currencyText ? currencyText : el?.prevailingCurrency)
      )?.symbol,
    }
  ).format()} - ${el?.InvoiceCat || ""}`;
}

function CustomerRow({
  index,
  customer,
  editTable,
  remove,
  customers = [],
  addNewRow,
  backendUrl,
  getCustomerBalanceAndCredit,
  addToParentCustomerList,
}) {
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
  } = useCurrencies();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const [duePaymentOptions, setDuePaymentOptions] = useState([]);
  const [invoiceCatOptions, setInvoiceCatOptions] = useState([]);
  const [salesRepOptions, setSalesRepOptions] = useState([]);

  const initialValues = {
    Post_Date: moment(),
    newInstallment: "",
    Remark: "",
    PaymentType: "Cash",
    duePaymentTransactionID: "",
    chequeNumber: "",
    BankName: "",
    Cust_ID: "",
    InvoiceCat: "None",
    currency: generalSettings?.prevailingCurrency,
    conversionAmount: generalSettings.dollarInBaseCurrency,
    otherTaxValues: [],
    newInstallmentAfterTax: "",
  };
  const formik = useFormik({
    initialValues,
    // validationSchema: yup.object().shape({}),
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Cust_ID) => {
    const customer = customers.find((el) => el?.Cust_ID === Cust_ID);

    if (Cust_ID && isEmpty(customer)) {
      return getCustomerBalanceAndCredit(Cust_ID);
    }

    // GET CUSTOMER BALANCE & CREDIT
    const duePayments = customer?.duePayments ? customer.duePayments : [];
    const invoiceCats = customer?.invoiceCats ? customer.invoiceCats : [];
    const salesRepData = customer?.salesRepData ? customer.salesRepData : [];
    if (
      isEmpty(customer?.balance) ||
      isEmpty(duePayments) ||
      isEmpty(invoiceCats) ||
      isEmpty(salesRepData)
    ) {
      getCustomerBalanceAndCredit(Cust_ID);
    }

    setDuePaymentOptions([
      {
        label: "None",
        value: "",
      },
      ...duePayments.map((el) => ({
        ...el,
        prevailingCurrency,
        label: duePaymentLabel({ ...el, prevailingCurrency }),
        value: el.Trans_ID,
      })),
    ]);

    setInvoiceCatOptions([
      {
        label: "None",
        value: "None",
      },
      ...invoiceCats.map((el) => ({
        label: el.InvoiceCat,
        value: el.InvoiceCat,
      })),
    ]);

    // console.log(salesRepData, "skk");

    setSalesRepOptions([
      {
        label: "None",
        value: "None",
      },
      ...salesRepData.map((el) => ({
        label: employeeFullName(el),
        value: el.EmployeeID,
      })),
    ]);

    // formik.setFieldValue("newInstallment", duePayments[0]?.AmountDue);
    formik.setFieldValue("Cust_ID", Cust_ID);
  };

  useEffect(() => {
    if (customer?.duePayments) {
      setDuePaymentOptions([
        {
          label: "None",
          value: "",
        },
        ...customer?.duePayments.map((el) => ({
          ...el,
          prevailingCurrency,
          label: duePaymentLabel({ ...el, prevailingCurrency }),
          value: el.Trans_ID,
        })),
      ]);
    }
  }, [customer?.duePayments]);

  useEffect(() => {
    if (customer?.invoiceCats) {
      setInvoiceCatOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer?.invoiceCats.map((el) => ({
          label: el.InvoiceCat,
          value: el.InvoiceCat,
        })),
      ]);
    }
  }, [customer?.invoiceCats]);

  useEffect(() => {
    if (customer?.salesRepData) {
      setSalesRepOptions([
        {
          label: "None",
          value: "None",
        },
        ...customer.salesRepData.map((el) => ({
          label: employeeFullName(el),
          value: el.EmployeeID,
        })),
      ]);
    }
  }, [customer?.salesRepData]);

  useEffectOnce(() => {
    if (customer?.Cust_ID) setUp(customer.Cust_ID);
  });

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }

    editTable({
      index,
      formValues: {
        ...(formValues?.Cust_ID
          ? customers.find((el) => el?.Cust_ID === formValues?.Cust_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
      formik.setFieldValue("currency", bank.currency);
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    } else if (formik.values.PaymentType === "Cash") {
      formik.setFieldValue("currency", generalSettings?.prevailingCurrency);
    }
  }, [formik.values.PaymentType]);

  const handleCustomerChange = (value) => {
    formik.setFieldValue("Cust_ID", value);
    if (value) {
      console.log(value);
      setUp(value);
      addNewRow(index);
    }
  };

  const fetchCustomers = async (inputValue, callback) => {
    try {
      let response = await fetch(
        `${backendUrl}/api/customers?page=1&Limit=50&LastName=${inputValue}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const { data } = await response.json();
      if (data.customers)
        callback(
          (data.customers = data.customers.map((el) => ({
            ...el,
            label: customerFullName(el),
            value: el.Cust_ID,
          })))
        );
    } catch (err) {
      console.log(err);
      callback();
    }
  };

  const debouncedFetchUsers = _.debounce(fetchCustomers, 500);

  const loadOptions = (inputValue, callback) => {
    try {
      if (_.isEmpty(inputValue)) {
        return callback(null, { options: [] });
      }
      debouncedFetchUsers(inputValue, callback);
    } catch (err) {
      console.log(err)
      return callback(null, { options: [] });
    }
  };

  eventBus.useCustomEventListener(
    "SET_CUSTOMER_TO_PAY",
    ({ customer, customerToPayDuePayment }) => {
      // console.log(customer);
      handleCustomerChange(customer?.value ? customer?.value : "");
      addToParentCustomerList(customer);

      //  console.log(customer, customerToPayDuePayment, "kkkkk");

      // Select Payment
      const amountDue =
        customerToPayDuePayment?.Payment_Due_Transaction?.currency &&
          customerToPayDuePayment?.Payment_Due_Transaction?.currency !==
          generalSettings?.prevailingCurrency
          ? currency(customerToPayDuePayment.AmountDue).divide(
            customerToPayDuePayment?.Payment_Due_Transaction?.conversionAmount
          ).value
          : customerToPayDuePayment.AmountDue;

      formik.setFieldValue(
        "duePaymentTransactionID",
        customerToPayDuePayment.value
      );
      formik.setFieldValue("newInstallment", amountDue);
      formik.setFieldValue(
        "currency",
        customerToPayDuePayment?.Payment_Due_Transaction?.currency || "NGN"
      );
      formik.setFieldValue(
        "conversionAmount",
        customerToPayDuePayment?.Payment_Due_Transaction?.conversionAmount
      );

      if (customer.Cust_ID === "000101") {
        formik.setFieldValue(
          "Remark",
          customerToPayDuePayment?.Payment_Due_Transaction?.ShipTo || ""
        );
      }

      formik.setFieldValue(
        "Sale_Rep",
        customerToPayDuePayment?.Payment_Due_Transaction?.Sale_Rep
      );

      formik.setFieldValue("newInstallmentBeforeTax", amountDue);
    }
  );

  const childRef = useRef();

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVerticalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              Remove Customer
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>

      {/*   <td>{customer.Cust_ID}</td> */}
      <td>
        {/* {customer?.Cust_ID} */}
        <Select
          classNamePrefix="form-select"
          menuPlacement="bottom"
          menuPosition="fixed"
          placeholder="Choose method"
          isSearchable={false}
          value={paymentMethod.find(
            (el) => el.value === formik.values.PaymentType
          )}
          options={paymentMethod}
          onChange={(selected) =>
            formik.setFieldValue("PaymentType", selected?.value)
          }
          getOptionLabel={(el) => (
            <div className="label-with-icon d-flex gap-2 align-items-center">
              <span>{el.icon}</span> <span className="fw-5">{el.label}</span>
            </div>
          )}
        //  isClearable
        />
      </td>

      <td>
        <AsyncSelect
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          data={customers}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={customers?.find((el) => el?.value === formik.values?.Cust_ID)}
          defaultOptions={customers || []}
          onChange={(selection) => {
            // console.log(selection);
            handleCustomerChange(selection?.value ? selection?.value : "");
            addToParentCustomerList(selection);
          }}
          id={`newInstallment-customer-${index}`}
          loadOptions={loadOptions}
        />{" "}
      </td>

      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>

      <td>
        <div className="d-flex gap-3">
          <Form.Group>
            <Select
              classNamePrefix="form-select"
              placeholder="Select Currency"
              isSearchable={false}
              options={currenciesOptions}
              value={currenciesOptions.find(
                (el) => el.value === formik.values.currency
              )}
              onChange={({ value }) => formik.setFieldValue("currency", value)}
              menuPosition="fixed"
              isDisabled
            />
          </Form.Group>

          {formik.values.currency &&
            formik.values.currency !== generalSettings?.prevailingCurrency ? (
            <Form.Group>
              <CurrencyCustomInput
                currencySymbol={"Ex. Rate"}
                name="conversionAmount"
                value={formik.values.conversionAmount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                placeholder="0.00"
              />
            </Form.Group>
          ) : null}
        </div>
      </td>

      <td>
        <Select
          classNamePrefix={"form-select"}
          className="customer-select-dropdown"
          menuPlacement="bottom"
          menuPosition="fixed"
          options={duePaymentOptions.filter(
            (el) =>
              el?.Payment_Due_Transaction?.currency === formik.values.currency
          )}
          key={duePaymentOptions}
          isSearchable={false}
          onChange={(selected) => {
            if (selected?.value) {
              const amountDue =
                selected?.Payment_Due_Transaction?.currency &&
                  selected?.Payment_Due_Transaction?.currency !==
                  generalSettings?.prevailingCurrency
                  ? currency(selected.AmountDue).divide(
                    selected?.Payment_Due_Transaction?.conversionAmount
                  ).value
                  : selected.AmountDue;

              let defaultTaxValue = 0;
              let otherTaxValuesTotal = 0;
              let otherTaxes = []
              try {
                // figure out tax value
                defaultTaxValue = selected?.Payment_Due_Transaction?.taxValue || 0;
                otherTaxes = (selected?.Payment_Due_Transaction?.otherTaxValues ? JSON.parse(selected?.Payment_Due_Transaction?.otherTaxValues) : []);
                otherTaxValuesTotal = otherTaxes.reduce(
                  (sum, tax) => currency(sum).add(tax.taxValue).value,
                  0
                );

              } catch (err) {
                console.log(err);
                toast.error("Issue getting Tax, please set manually if any");
                defaultTaxValue = 0;
                otherTaxValuesTotal = 0;
                otherTaxes = [];
              }

              //    console.log(otherTaxValuesTotal, defaultTaxValue);

              const valueWithOutTax = currency(amountDue).subtract(defaultTaxValue).subtract(otherTaxValuesTotal).value;

              const taxes = [{ taxValue: selected?.Payment_Due_Transaction?.taxValue, taxType: selected?.Payment_Due_Transaction?.taxType }, ...otherTaxes];
              if (childRef.current) {
                childRef.current.initialTaxes(taxes); // Call the child's function
              }

              formik.setFieldValue("duePaymentTransactionID", selected.value);
              formik.setFieldValue("newInstallment", amountDue);
              formik.setFieldValue(
                "currency",
                selected?.Payment_Due_Transaction?.currency || "NGN"
              );
              formik.setFieldValue(
                "conversionAmount",
                selected?.Payment_Due_Transaction?.conversionAmount
              );

              if (customer.Cust_ID === "000101") {
                formik.setFieldValue(
                  "Remark",
                  selected?.Payment_Due_Transaction?.ShipTo || ""
                );
              }

              formik.setFieldValue(
                "Sale_Rep",
                selected?.Payment_Due_Transaction?.Sale_Rep
              );

              formik.setFieldValue("newInstallmentBeforeTax", valueWithOutTax);
            }
          }}
          value={duePaymentOptions
            .filter(
              (el) =>
                el?.Payment_Due_Transaction?.currency === formik.values.currency
            )
            .find(
              (el) => el.Trans_ID === formik.values?.duePaymentTransactionID
            )}
        />
      </td>

      <td>
        {/*   {JSON.stringify(formik.values.otherTaxValues)} */}
        <TaxPicker
          ref={childRef}
          newInstallmentBeforeTax={formik.values.newInstallmentBeforeTax}
          setNewInstallmentBeforeTax={(amount) =>
            formik.setFieldValue("newInstallmentBeforeTax", amount)
          }
          newInstallment={formik.values.newInstallment}
          setNewInstallment={(amount) =>
            formik.setFieldValue("newInstallment", amount)
          }
          setTaxes={(otherTaxValues) =>
            formik.setFieldValue("otherTaxValues", otherTaxValues)
          }
          newInstallmentAfterTax={formik.values.newInstallmentAfterTax}
          setNewInstallmentAfterTax={(amount) =>
            formik.setFieldValue("newInstallmentAfterTax", amount)
          }
          paymentCurrency={formik.values.currency}
        />
        {/*  <CurrencyCustomInput
          currencySymbol={
            currenciesOptions.find(
              (curr) =>
                curr.cc ===
                (formik.values.currency
                  ? formik.values.currency
                  : generalSettings?.prevailingCurrency)
            )?.symbol
          }
          id={`newInstallment-${customer.Cust_ID}`}
          name="newInstallment"
          placeholder="0.00"
          defaultValue=""
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        /> */}
      </td>

      <td>
        <FilesAttachments
          files={formik.values.attachments}
          setFiles={(files) => formik.setFieldValue("attachments", files)}
          colWidth="12"
          url={`${backendUrl}/api/vendors/view-invoice`}
        />
      </td>

      <td>
        {currency(customer.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${formik.touched.Post_Date && !!formik.errors.Post_Date
                  ? "is-invalid"
                  : ""
                  }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>

      <td>{customer.TransactionID}</td>

      <td>
        <Select
          classNamePrefix={"form-select"}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={false}
          options={invoiceCatOptions}
          key={invoiceCatOptions}
          onChange={({ value }) => formik.setFieldValue("InvoiceCat", value)}
        />
      </td>

      {appSettings.requireSalesRep && (
        <td>
          <Select
            classNamePrefix={"form-select"}
            menuPlacement="bottom"
            menuPosition="fixed"
            isSearchable={false}
            options={salesRepOptions}
            key={salesRepOptions}
            value={salesRepOptions.find(
              (option) => option.value === formik.values.Sale_Rep
            )}
            onChange={({ value }) => formik.setFieldValue("Sale_Rep", value)}
          />
        </td>
      )}
    </tr>
  );
}

export default function PostPayment() {
  const isGovernmentInvexERP = useIsGovernmentInvexERP();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token } = useAuth();
  const [showCustomerSelectorModal, setShowCustomerSelectorModal] = useState(
    false
  );

  const generalSettings = useStoreState((state) => state.generalSettings);
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const location = useLocation();
  const navigate = useNavigate();

  const setUpCustomerToPay = async () => {
    await waitFor(1000);

    // console.log(location.state);

    if (location.state) {
      if (
        location.state?.customerToPay &&
        location.state?.customerToPayDuePayment
      )
        eventBus.dispatch("SET_CUSTOMER_TO_PAY", {
          customer: location.state.customerToPay,
          customerToPayDuePayment: location.state.customerToPayDuePayment,
        });
    }

    await waitFor(100);

    navigate(location.pathname, { replace: true });
  };

  useEffectOnce(() => {
    setUpCustomerToPay();
  });

  const [tableData, setTableData] = useState([
    {
      Cust_ID: "",
      TransactionID: `STN${Date.now()}1`,
    },
  ]);

  const setSelectedCustomer = (customer) => {
    const lastitem = last(tableData);
    customer.TransactionID = `STN${Date.now()}`;

    if (lastitem.Cust_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}2` },
      ]);
    } else {
      setTableData([
        ...tableData,
        customer,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    // console.log(formValues.newInstallment);
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Cust_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateCustomersLedger = async (payload) => {
    payload.customers = cloneDeep(payload.customers).map((el) => {
      if (el?.currency !== generalSettings?.prevailingCurrency) {
        el.notConvertedInstallment = el.newInstallment;
        const convertedData = convertToBaseCurrency({
          data: {
            newInstallment: el.newInstallment,
            newInstallmentAfterTax: el.newInstallmentAfterTax,
          },
          conversionAmount: el.conversionAmount,
        });
        el.newInstallment = convertedData.newInstallment;
        el.newInstallmentAfterTax = convertedData.newInstallmentAfterTax;
      }

      return el;
    });

    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // pick up files
    for (let customer of payload.customers) {
      if (!isEmpty(customer.attachments)) {
        for (let file of customer.attachments) {
          formData.append(
            `customerPaymentFile_${customer.TransactionID}`,
            file
          );
        }
      }
    }

    let response = await fetch(`${backendUrl}/api/customers/post-payments`, {
      method: "POST",
      headers: {
        /*   Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: formData,
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const updateCustomersLedgerMutation = useMutation(
    (payload) => updateCustomersLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        let uniqueId = `STN${Date.now()}1`;
        setTableData([...[{ Cust_ID: "", TransactionID: uniqueId }]]);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    if (
      !(await ConfirmDialog({
        title: "Post Transaction",
        description: "Are you sure, you want to make this transaction",
      }))
    ) {
      return;
    }
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyCustomerIndex = table.findIndex((el) => el.Cust_ID === "");
    if (foundEmptyCustomerIndex !== -1) {
      console.log(foundEmptyCustomerIndex);
      scrollToElement(`#newInstallment-customer-${foundEmptyCustomerIndex}`);
      return toast.error(`Select a Customer`);
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );
    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundEmptyInstallment.Cust_ID}`);
      return toast.error(`Invalid installment added`);
    }

    // Ensure only valid data are sent
    /* console.log(tableData);
    return; */
    const customersData = tableData.filter(
      (el) => el.Cust_ID && el.newInstallment
    );

    // console.log(customersData);
    //  return;

    updateCustomersLedgerMutation.mutate(
      {
        customers: customersData,
      },
      {
        onSuccess: () => {
          if (customersData && customersData.length === 1) {
            const customer = first(customersData);

            ReceiptDialog({
              title: "View Reciept",
              TransactionID: customer.TransactionID,
            });
            /*  window.open(
              `${backendUrl}/api/invoice/pdf/receipt/${customer.TransactionID}`,
              "_blank",
              "noopener,noreferrer"
            ); */
          }
        },
      }
    );
  };

  const getCustomer = async () => {
    let response = await fetch(
      // `${backendUrl}/api/customers?withCredit=${true}&withPaymentDue=${true}`,
      `${backendUrl}/api/customers?page=1&limit=50`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.customers = data.customers.map((el) => ({
      ...el,
      label: customerFullName(el),
      value: el.Cust_ID,
    }));
    return data;
  };

  const { data = { customers: [] }, isFetching } = useQuery(
    [queryActions.CUSTOMERS],
    () => getCustomer(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const addToParentCustomerList = (newCustomerFromSearch) => {
    queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
      if (
        !data.customers.find(
          (el) => el?.Cust_ID === newCustomerFromSearch?.Cust_ID
        )
      ) {
        data.customers = [...data.customers, newCustomerFromSearch];
        return data;
      } else {
        return data;
      }
    });
  };

  const getCustomerBalanceAndCredit = async (Cust_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      //await waitFor(4000);
      let response = await fetch(
        `${backendUrl}/api/customers/balance-and-credit/${Cust_ID}?key=${Date.now()}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats, salesRepData },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData([queryActions.CUSTOMERS], (data) => {
          data.customers = data.customers.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats, salesRepData }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Cust_ID === Cust_ID
              ? { ...el, balance, duePayments, invoiceCats, salesRepData }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  return (
    <IsPrivileged roleName="Receive Payments">
      <main className="create-invoice">
        <PageHeader
          name={isGovernmentInvexERP ? "Inbound Cash Flow" : "Post Payment"}
          description={isGovernmentInvexERP ? "" : "Update Customer Ledger"}
          icon={<MoneyDollarIcon />}
        />

        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header>
                  <h1>
                    {isGovernmentInvexERP
                      ? "Inbound Cash Flow"
                      : "Post Payment"}
                  </h1>
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            {/* <th>Customer ID</th> */}
                            <th>Payment Type</th>
                            <th>
                              {isGovernmentInvexERP
                                ? "Source Name"
                                : "Business Name"}{" "}
                            </th>
                            <th>Remark</th>
                            <th>Currency</th>
                            <th>Link Payment to Invoice</th>
                            <th>Amount</th>
                            <th className="text-nowrap">
                              Attachments (Payment Advice / Invoice etc)
                            </th>
                            <th>Balance on Ledger</th>
                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            <th>Invoice Type</th>
                            {appSettings.requireSalesRep && <th>Sales Rep</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <CustomerRow
                                customers={data?.customers}
                                index={index}
                                customer={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getCustomerBalanceAndCredit={
                                  getCustomerBalanceAndCredit
                                }
                                addToParentCustomerList={
                                  addToParentCustomerList
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>

                  <button
                    onClick={() => setShowCustomerSelectorModal(true)}
                    type="button"
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add Customer
                  </button>

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">
                          Haven't selected a Customer yet
                        </h2>
                        <p>
                          You can click +Add Customer Button to add a Customer
                          to the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={updateCustomersLedgerMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      {isGovernmentInvexERP ? "Post" : "Post Payments"}
                    </Button>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showCustomerSelectorModal && (
          <CustomerSelectModal
            setShowCustomerSelectorModal={setShowCustomerSelectorModal}
            setSelectedCustomer={setSelectedCustomer}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedCustomers={tableData}
            withInvoiceCat={true}
          />
        )}

        <ModalLoader
          show={updateCustomersLedgerMutation.isLoading || isFetching}
        />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting customer balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}
