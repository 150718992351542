import { isEmpty, upperFirst } from "lodash";
import { useState, useEffect } from "react";
import noImage from "../../assets/images/profile.jpg";

import "./../../assets/scss/scoped/avatar.scoped.scss";
import { useAuth } from "../../hooks/useAuth";
import { useBackendUrl, useCustomerBackendUrl } from "../../utils/hooks";

export default function Avatar({
  name,
  company = "",
  department = "",
  onFileUpload,
  nameInput,
  userId,
  image,
  extralText,
  vendor,
  rounded,
  ...props
}) {
  const [imageurl, setImageUrl] = useState("");
  const { user: authUser, authVendor, customerBackendUrl } = useAuth();
  const backendUrl = useBackendUrl();

  useEffect(() => {
    const fechIt = async () => {
      try {
        let getPics = null;
        if (vendor) {
          getPics = await fetch(
            `${backendUrl}/api/vendors/profile-pics/${
              userId ? userId : authVendor?.Vendor_ID
            }`
          );
        } else {
          getPics = await fetch(
            `${
              !isEmpty(props.Cust_ID) ? customerBackendUrl : backendUrl
            }/api/users/profile-pics/${userId ? userId : authUser?.Staff_ID}`
          );
        }

        if (getPics.ok) {
          const profilePics = await getPics.blob();
          setImageUrl(profilePics);
        } else {
          setImageUrl("");
        }
      } catch (error) {
        setImageUrl("");
      }
    };

    fechIt();
  }, [
    userId,
    authUser?.Staff_ID,
    backendUrl,
    customerBackendUrl,
    props?.Cust_ID,
    authVendor?.Vendor_ID,
    vendor,
  ]);

  useEffect(() => {
    image && setImageUrl(image);
  }, [image]);
  return (
    <div
      className={`${props?.notcircle ? "rounded" : "rounded-circle"} avatar`}
      {...props}
    >
      {!imageurl ? (
        <div
          className="d-flex align-items-center justify-content-center w-100 h-100"
          title={
            extralText
              ? extralText
              : `${name} - ${department} ${company ? `(${company})` : ""}`
          }
        >
          <label className="d-flex align-items-center justify-content-center  p-cursor w-100 h-100">
            {onFileUpload && (
              <input
                type="file"
                onChange={onFileUpload}
                name={nameInput}
                className="d-none"
              />
            )}
            {extralText ? (
              <small className="text-center fw-bold">{extralText}</small>
            ) : (
              <div className="fw-bold fs-6">
                {upperFirst(name ? name[0] : "...")}
              </div>
            )}
          </label>
        </div>
      ) : (
        <label className="text-center d-flex  p-cursor w-100 h-100">
          {onFileUpload && (
            <input
              type="file"
              onChange={onFileUpload}
              name={nameInput}
              className="d-none"
            />
          )}
          <img
            src={
              imageurl?.type?.split("/")[0] === "image"
                ? URL.createObjectURL(imageurl)
                : noImage
            }
            alt="pics"
            className={`w-100 h-100 img-fluid rounded ${
              rounded ? "rounded-circle" : ""
            }`}
            style={{ objectFit: "cover" }}
          />
        </label>
      )}
    </div>
  );
}
