import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
  ExcelIcon,
  PDFIcon,
} from "../../Icons";
import "../../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../../utils/hooks";
import { reportActions } from "../../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse, parseISO, startOfYesterday, subDays } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  formatDate,
  formatForQty,
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  reactSelectTheme,
} from "../../../utils/helpers";
import RsDateRangePicker from "../../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ItemsTable from "../../utils/ItemsTable";
import { isEmpty } from "lodash";
import NoTableItem from "../../utils/NoTableItem";
import { useDownloadExcel } from "../../../hooks/useDownloadExcel";
import ModalLoader from "../../utils/ModalLoader";
import { useStoreState } from "easy-peasy";
import { addDays } from "date-fns/esm";
import TableComponent from "../../TableComponent";
import ConvertQuantity from "../../utils/ConvertQuantity";
import Datetime from "react-datetime";
import { useNavigate } from "react-router-dom";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { IsPrivileged } from "../../DisplayChildElement";

export default function StockRecordsWarehouse() {
  const { whID: warehouseId } = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );
  useScrollTop();

  const initialFilterParams = {
    itemId: "",
    itemName: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    sync: true,
  };

  const { backendUrl, isIronRod } = useAuth();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const navigate = useNavigate();

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    //...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchAnalysis = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/warehouse_routes/reports/${warehouseId}/stock-records?${queryString.stringify(
        queryParams
      )}`,

      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    console.log(data);
    data.branch = [
      {
        label: "All",
        value: "",
      },
      ...data.branch.map((el) => ({
        label: el.Branch,
        value: el.Branch,
      })),
    ];

    return data;
  };

  const {
    data = {
      count: 0,
      inventory: [],
    },
    refetch,
    isSuccess,
    isFetching,
  } = useQuery(
    [reportActions.INVENTORY_ANALYSIS, queryParams],
    () => fetchAnalysis(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/warehouse_routes/reports/${warehouseId}/stock-records?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = data.inventory.map((el, i) => [
      el.Bar_Code,
      el.Item_Name,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.OpeningQty, el.Item_Desc, itemMeasurements)
        )
        : el.OpeningQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.RecievedQty, el.Item_Desc, itemMeasurements)
        )
        : el.RecievedQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.SoldQty, el.Item_Desc, itemMeasurements)
        )
        : el.SoldQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.ReturnQty, el.Item_Desc, itemMeasurements)
        )
        : el.ReturnQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.ConvertedQty, el.Item_Desc, itemMeasurements)
        )
        : el.ConvertedQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.DamagedQty, el.Item_Desc, itemMeasurements)
        )
        : el.DamagedQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.IssuedQty, el.Item_Desc, itemMeasurements)
        )
        : el.IssuedQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.ShortageQty, el.Item_Desc, itemMeasurements)
        )
        : el.ShortageQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.ExcessQty, el.Item_Desc, itemMeasurements)
        )
        : el.ExcessQty,
      isIronRod
        ? qtyFormatToString(
          qtyFormat(el.ClosingQty, el.Item_Desc, itemMeasurements)
        )
        : el.ClosingQty,
      el.Date ? formatDate(el.Date) : "",
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
          new Date(rest.startDate),
          "E MMM d yyyy k:mm:ss z"
        )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Stock Reports"],
      [date],
      [""],
      [
        "Item ID",
        "Item name",
        "Opening QTY",
        "QTY Received",
        "QTY Sold",
        "QTY Returned",
        "QTY Converted",
        "QTY Damaged",
        "QTY Issued",
        "Shortage Added",
        "Excess Removed",
        "Closing QTY",
        "Date",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Opening Qty",
        currency(data.OpeningQty, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
      [
        "Total Recieved",
        currency(data.RecievedQty, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
      [
        "Total Sold",
        currency(data.SoldQty, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
      [
        "Total Closing Qty",
        currency(data.ClosingQty, {
          symbol: "",
          precision: 2,
          format: formatForQty,
        }).format(),
      ],
    ];

    // console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
      sync: false,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
      sync: false,
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      itemId: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  const nextDate = () => {
    setFilterParams({
      ...filterParams,
      startDate: format(
        addDays(new Date(queryParams.startDate), 1),
        "yyyy-MM-dd"
      ),
      endDate: format(
        addDays(new Date(queryParams.startDate), 1),
        "yyyy-MM-dd"
      ),
      enableDateRange: true,
      page: 1,
      sync: false,
    });
  };

  const prevDate = () => {
    setFilterParams({
      ...filterParams,
      startDate: format(
        subDays(new Date(queryParams.startDate), 1),
        "yyyy-MM-dd"
      ),
      endDate: format(
        subDays(new Date(queryParams.startDate), 1),
        "yyyy-MM-dd"
      ),
      enableDateRange: true,
      page: 1,
      sync: false,
    });
  };

  const openSalesAnalysis = (item) => {
    navigate(
      `/reports/sales-analysis?${queryString.stringify({
        startDate: format(new Date(queryParams.startDate), "yyyy-MM-dd"),
        endDate: format(new Date(queryParams.endDate), "yyyy-MM-dd"),
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
      })}`
    );
  };

  const openPreorders = (item) => {
    navigate(
      `/sales-and-invoicing/pre-order?${queryString.stringify({
        startDate: format(new Date(queryParams.startDate), "yyyy-MM-dd"),
        endDate: format(new Date(queryParams.endDate), "yyyy-MM-dd"),
        barcode: item.Bar_Code,
        itemName: item.Item_Name,
      })}`
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>Date</th>
          <th>Item ID</th>
          <th>Item name</th>
          <th>Opening QTY</th>
          <th>QTY Received</th>
          <th>QTY Sold</th>
          <th>QTY Supplied</th>
          <th>QTY Not Supplied</th>
          <th>QTY Returned</th>
          <th>QTY Converted</th>
          <th>QTY Damaged</th>
          <th>QTY Issued</th>
          <th>QTY Rejected</th>
          <th>QTY Pre-ordered</th>
          <th>Shortage Added</th>
          <th>Excess Removed</th>
          <th>Closing QTY</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item onClick={() => openSalesAnalysis(el)} as="button">
                <EyeOutlineIcon />
                View in Sales
              </Dropdown.Item>
              <Dropdown.Item onClick={() => openPreorders(el)} as="button">
                <EyeOutlineIcon />
                View in Preorder
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>
        <td className="text-nowrap">{el.Date ? formatDate(el.Date) : ""}</td>
        <td>{el.Bar_Code}</td>
        <td>{el.Item_Name}</td>
        <td>
          <ConvertQuantity quantity={el.OpeningQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.RecievedQty} desc={el.Item_Desc} />
        </td>
        <td
          className="p-cursor"
          onClick={() => openSalesAnalysis(el)}
          title="View in Sales"
        >
          <ConvertQuantity quantity={el.SoldQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el?.SuppliedQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el?.NotSuppliedQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.ReturnQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.ConvertedQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.DamagedQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.IssuedQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.RejectedQty} desc={el.Item_Desc} />
        </td>
        <td
          className="p-cursor"
          onClick={() => openPreorders(el)}
          title="View in Preorder"
        >
          <ConvertQuantity quantity={el.PreorderQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.ShortageQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.ExcessQty} desc={el.Item_Desc} />
        </td>
        <td>
          <ConvertQuantity quantity={el.ClosingQty} desc={el.Item_Desc} />
        </td>
        <td className="text-nowrap">{el.Date ? formatDate(el.Date) : ""}</td>
      </>
    );
  };
  // isprivileged
  return (
    <IsPrivileged roleName="Stock Monitor">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Branch</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        placeholder="All"
                        name="branch"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.branch}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            branch: selected.value,
                          });
                        }}
                        defaultValue={defaultSelectValue(
                          queryParams?.branch,
                          data?.branch,
                          {
                            value: "",
                            label: "All",
                          }
                        )}
                        options={data?.branch || []}
                      />
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item ID</Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="itemId"
                          placeholder="Enter Item ID"
                          value={filterParams?.itemId}
                          onChange={(e) => handleFilterParamsChange(e)}
                        />

                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowItemsPopover(!showItemsPopover)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2-5">
                      <Form.Label>Item Name</Form.Label>
                      <Form.Control
                        name="itemName"
                        value={filterParams?.itemName}
                        placeholder="Enter Item name"
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </Form.Group>

                    <div className="">
                      <Form.Check
                        inline
                        label="Show for all items"
                        name="showForAllItems"
                        type={"checkbox"}
                        id={`showForAllItems`}
                        defaultChecked={JSON.parse(
                          filterParams?.showForAllItems || true
                        )}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <hr className="mt-1 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        checked={filterParams?.enableDateRange}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>

            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter "
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Stock Monitor
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="d-flex align-items-center gap-3">
                  <button onClick={() => prevDate()} className="btn bg-lighter">
                    Previous
                  </button>

                  {/*   <Form.Control
                  className="text-center "
                  value={
                    queryParams?.startDate
                      ? format(new Date(queryParams?.startDate), "dd MMM, yyyy")
                      : "..."
                  }
                  readOnly
                />*/}

                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    dateFormat="DD MMM, YYYY"
                    name="salesDate"
                    inputProps={{
                      className: `form-control text-center`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={
                      queryParams?.startDate
                        ? format(
                          new Date(queryParams?.startDate),
                          "dd MMM, yyyy"
                        )
                        : ""
                    }
                    onChange={(date) => {
                      date = parseISO(date.format());
                      filterByDateRange([date, date]);
                    }}
                  />

                  <button
                    onClick={() => nextDate()}
                    className="btn bg-lighter px-4"
                  >
                    Next
                  </button>
                </div>
                <div className="actions mr-5">
                  {/*  <form action="align-items-center">
              <MagnifyIcon className="searchIcon" />
              <input
                type="text"
                name="search"
                placeholder="Search Item ID or item name..."
              />
            </form> */}
                  {/*   <button className="btn print">
                  Print <PrintIcon />
                </button> */}{" "}
                  <Form.Switch
                    label="Sync"
                    checked={filterParams.sync}
                    onChange={() => {
                      setFilterParams({
                        ...filterParams,
                        sync: !filterParams.sync,
                        ...(filterParams.sync === false
                          ? {
                            startDate: format(new Date(), "yyyy-MM-dd"),
                            endDate: format(new Date(), "yyyy-MM-dd"),
                          }
                          : {}),
                      });
                    }}
                  />
                  <CSVLink
                    className="btn print d-none"
                    filename={`Stock Reports(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/stock-records?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <button
                    onClick={() => setBtnSummaryActive(true)}
                    className="btn summaryBtnWhite btn-outline-primary "
                  >
                    Show Summary
                  </button>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data.inventory}
                  tableDataRowFunction={tableData}
                  className="product-table"
                />

                {!isFetching && isSuccess && isEmpty(data?.inventory) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>

              {/* <div className="p-4 border-top">
            <h2>Summary</h2>
            <div className="summary">
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Cash Sales</td>
                    <td>5,800.00</td>
                </tr>

                <tr>
                    <td>Total Cash PMT</td>
                    <td>350,250,895.00</td>
                </tr>

                <tr>
                    <td>Total Credit/Debit Card/Cheque</td>
                    <td>1,204,449,629.06</td>
                </tr>

                <tr>
                    <td>Total Cash</td>
                    <td>350,256,695.00</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Credit Sales</td>
                    <td>1,688,912,055.73</td>
                </tr>

                <tr>
                    <td>Total Posted</td>
                    <td>3,243,668,379.79</td>
                </tr>
                </tbody>
            </table>
            <table className="table table-borderless balance">
                <tbody>
                <tr>
                    <td>Total Expenses</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>POS to Bank</td>
                    <td>103,366,800.00</td>
                </tr>

                <tr>
                    <td>Direct Bank Transfer</td>
                    <td>1,091,328,596.19</td>
                </tr>

                <tr>
                    <td>Cash Sent to Bank</td>
                    <td>0.00</td>
                </tr>

                <tr>
                    <td>Cash at Hand</td>
                    <td>337,732,030.10</td>
                </tr>
                </tbody>
            </table>
            </div>
        </div> */}

              <div
                className={
                  btnSummryActive
                    ? `summaryNew border-top open`
                    : `summaryNew border-top`
                }
              >
                <div className="summaryHeader">
                  <h2>Summary</h2>
                  {btnSummryActive && (
                    <button onClick={() => close()} className="btn p-0">
                      <SummaryCloseIcon />
                    </button>
                  )}
                </div>

                <div className="gridCont">
                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.OpeningQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Opening Qty</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.RecievedQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Recieved</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftOrange">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.SoldQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Sold</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.SuppliedQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Supplied</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.NotSuppliedQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Not Supplied</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconUp />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftBrown">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.ReturnQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Return Qty</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>

                  <div className="gridChild gridChildBorderLeftDarkerGreen">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(data.ClosingQty, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <p className="gridChld2">Total Closing Qty</p>
                    </div>

                    <div className="gridChildRight">
                      <SummaryChartIconDown />
                      <p>5%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader
          show={isfetchingExcel || (isFetching && filterParams.sync)}
        />
      </main>
    </IsPrivileged>
  );
}
