import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { backendApis } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import {
  formDataForFormBuider,
  formatDate,
  formatNumberSystem,
} from "../../utils/helpers";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import ConfirmDialog from "../ConfirmDialogue";
import { Logo } from "../Icons";
import ModalLoader from "../utils/ModalLoader";
import { useStoreState } from "easy-peasy";
import { isEmpty } from "lodash";

export default function EditForm() {
  const { id, company } = useParams();
  //  get company from  url,  because in  public  form  the user  is  not logged  in
  const { url: backendUrl } = backendApis.find((el) => el.name === company);
  const { user: authUser } = useAuth();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const iframeRef = useRef();
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);
  const formik = useFormik({
    initialValues: {
      id: id,
      UserName: authUser?.Name || "",
      Staff_ID: authUser?.Staff_ID || "",
      filledFormData: "",
      formName: "",
    },
    validationSchema: yup.object().shape({
      formName: yup.string().required(""),
    }),
    onSubmit: async (values) => {
      editFilledFormMutation.mutate(
        { ...values },
        {
          onSuccess: (data) => { },
          onError: ({ errors }) => {
            if (errors) formik.setErrors(errors);
          },
        }
      );
    },
  });

  const editFilledForm = async (payload) => {
    payload.Template = payload.filledFormData;
    const formData = formDataForFormBuider(payload);

    let response = await fetch(`${backendUrl}/api/forms/edit-filled-form`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const editFilledFormMutation = useMutation(
    (payload) => editFilledForm(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const setformData = async (data) => {
    formik.setFieldValue("filledFormData", data);

    if (
      await ConfirmDialog({
        title: "Submit",
        description: "Are you sure, you want to submit",
      })
    ) {
      formik.submitForm();
    }
  };

  const handleMessage = (message) => {
    if (message.data.type === "CLOSE") {
      //
    } else if (message.data.type === "SAVE") {
      setformData(message.data.value);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  //----------------------------------------------------------------------
  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_IN_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  //------------------------
  //  GEt Form  for filling
  const getForm = async () => {
    let response = await fetch(
      `${backendUrl}/api/forms/get-filled-form/${id}?withParentSettings=true`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data?.form.formName) {
      formik.setFieldValue("formName", data?.form.formName);
    }
    /*  if (!data?.form?.enabled) {
      setIsLoadingIframe(false);
    } */
    return data;
  };
  const getFormQuery = useQuery(["GET_FORM"], () => getForm(), {
    enabled: true,
  });

  const setOrientation = (printOrientation) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "OREINTATION",
          data: {
            ...printOrientation,
          },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const sendSetupData = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            template: JSON.parse(getFormQuery.data.form.filledFormData),
            backendUrl,
            companyData: currentLoggedInCompany,
            formInfo: {
              formNumber: formatNumberSystem(
                getFormQuery.data.form.formNumberPrefix,
                getFormQuery.data.form.currentNumber
              ),
              documentNumber: getFormQuery.data.form?.documentNumber,
              revision: getFormQuery.data.form.revision,
              date: formatDate(getFormQuery.data.form.Date_Log, "MMM yyyy"),
              Title: getFormQuery.data.form?.Title,
            },
          },
          settings: { canFill: true, showTableCheckbox: false },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      console.log(files, elementId, elementType);
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  return (
    <div>
      <Form.Group>
        <div class="p-4   text-center w-50 mx-auto">
          <label for="formname">
            <b>Enter Form Name</b> <span className="text-danger">*</span>
          </label>
          <Form.Control
            name="formName"
            placeholder="Form Name"
            required
            value={formik.values.formName}
            onChange={formik.handleChange}
            isInvalid={formik.touched.formName && !!formik.errors.formName}
          />{" "}
          <Form.Control.Feedback type="invalid">
            {formik.errors.formName}
          </Form.Control.Feedback>
        </div>
      </Form.Group>

      {!isFetching && !getFormQuery.isFetching ? (
        getFormQuery?.data?.form?.enabled ? (
          <>
            <iframe
              ref={iframeRef}
              id="Frame"
              onLoad={(e) => {
                setIsLoadingIframe(false);
                sendSetupData();
                setOrientation({
                  orientation: getFormQuery.data.form.orientation,
                  pageSize: getFormQuery.data.form.pageSize,
                });
              }}
              className="w-100"
              style={{
                backgroundColor: "#E5E7EB",
                minHeight: "calc(100vh - 12rem)",
              }}
              title="Form Builder"
              src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
            />
            <Form noValidate onSubmit={formik.handleSubmit}>
              <input type={"hidden"} value={formik.values.FormID} />
            </Form>
            <div className="action-area w-100 p-4 text-center">
              <Button
                size="lg"
                className="px-5"
                type="button"
                onClick={() => save()}
                disabled={!getFormQuery?.data?.form?.enabled}
              >
                Save Changes
              </Button>{" "}
            </div>
          </>
        ) : (
          <div className="vh-100 vw-100 d-flex align-items-center justify-content-center text-center">
            <div>
              <Logo />
              <h1 className="display-5">Form Disabled</h1>
              <p className="">...</p>
            </div>
          </div>
        )
      ) : null}

      <ModalLoader
        show={isLoadingIframe || editFilledFormMutation.isLoading || isFetching}
      />
    </div>
  );
}
